/*
* @Author: 曹俊杰
* @Date: 2022-08-23 13:41:15
* @Module: 筛选表单
*/

<template>
    <div class="formBox">
        <el-form  :model="searchForm" size="mini" label-width="72px" label-suffix=":" label-position="left" ref="form"> 
            <el-row :gutter="40">
                <el-col :span="8">
                    <el-form-item label="合同编号" style="width:100%;" prop="keyword">
                        <el-input v-model="searchForm.keyword" @keyup.enter.native="onSubmit"
                            placeholder="请输入要搜索的客户名称、微信号码、手机号码" clearable></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="合同编号" style="width:100%;" prop="keyword2">
                        <el-input v-model="searchForm.keyword2" @keyup.enter.native="onSubmit"
                            placeholder="请输入要搜索的客户名称、微信号码、手机号码" clearable></el-input>
                    </el-form-item>
                </el-col>
               
                <el-col :span="8">
                    <el-form-item>
                        <div class="btn">
                            <el-button type="primary" style="width: 78px;" icon="el-icon-search" size="mini" @click="onSubmit">搜索</el-button>
                            <el-button size="mini" style="width: 78px;" @click="resetFields">重置</el-button>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>

export default {
    components: {
    },
    props: {
        searchForm: {
            type: Object,
            default: () => ({
                keyword: '',
                keyword2:''
            })
        }
    },
    data() {
        return {
        };
    },
    mounted() {

    },
    methods: {
        onSubmit() {
            this.$emit('submit')
        },
        resetFields(){
            this.$refs.form.resetFields()
        }
    },
};
</script>
<style lang='scss' scoped>
@import "~@/assets/style/searchFromStyle.scss";
</style>
